import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';
import { breakpoint } from '../../../../../../informakit/styles/shared';

export const Wrapper = styled.div`
  height: 100%;

  > div {
    height: 100%;
    a {
      display: flex;
      flex-direction: column;
      height: 100%;
      color: #ffffff;
      text-decoration: none;
      :hover {
        text-decoration: underline;
        svg {
          opacity: 1;
        }
      }

      svg {
        opacity: 0;
        transition: opacity 0.4s;
      }
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
  background-color: #333132;
  ${({ theme, primary }) =>
    renderStyledElementStyles(
      theme,
      primary && theme.module.vStyles.elements.card.settings.primary,
    )}
  ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}
  ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)}
`;

export const Name = styled.h3`
  font-weight: bold;
`;

export const Job = styled.h4`
  margin-top: 20px;
`;

export const Company = styled.h4`
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;

  @media screen and (min-width: ${breakpoint.tabletBreakpoint}) {
    min-height: 50px;
  }
`;

export const CompanyLogo = styled.div`
  img {
    width: 100%;
    height: 100%;
    max-height: 60px;
    object-fit: contain;
  }
`;

export const CenterStageIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;
