import s from './CarouselDisplayStyle.module.scss';
import Slider from '../../../../hub/shared/Slider';
import { SpeakerCard } from '../innerComponents';

const carouselSettings = {
  dots: true,
  arrows: false,
  autoplay: false,
  infinite: true,
  responsive: [
    {
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
      breakpoint: 1680,
    },
    {
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
      breakpoint: 1024,
    },
    {
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      breakpoint: 767,
    },
  ],
};

const layoutCarouselSettings = {
  PAGE: {
    slidesToShow: 4,
    slidesToScroll: 4,
  },
  FULLSCREEN: {
    slidesToShow: 6,
    slidesToScroll: 6,
  },
};

const scrollCarouselSettings = {
  SINGLE: {
    autoplay: true,
    slidesToScroll: 1,
  },
  PAGE: {
    autoplay: true,
  },
  SMOOTH: {
    dots: false,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 0,
    cssEase: 'linear',
  },
};

export default function CarouselDisplayStyle({ section }) {
  const {
    promotedSpeakers: slides = [],
    layoutType,
    rotateType,
    showCompanyLogos,
  } = section;

  const layoutClassName =
    layoutType === 'FULLSCREEN' ? 'container-fluid' : 'container';

  return (
    <div className={layoutClassName} data-testid="speakers-carousel">
      <div className="row">
        <div className="col-xs-12">
          <Slider
            slides={slides}
            settings={{
              ...carouselSettings,
              ...layoutCarouselSettings[layoutType],
              ...scrollCarouselSettings[rotateType],
            }}
            renderSlide={(slide, index) => (
              <SpeakerCard
                {...slide}
                primary={Boolean(index % 2)}
                showCompanyLogo={showCompanyLogos}
              />
            )}
            className={s.slider}
          />
        </div>
      </div>
    </div>
  );
}
