import { HubModule, HubModuleCTA } from '../../../../../../types';
import { HubModuleTypes } from '../../../../../../constants';
import {
  HubSpeakersDiscoveryBlock,
  SpeakerSection,
} from '../../../../../../store/features/discovery';

export enum SpeakerSectionContentTypes {
  EVENT = 'EVENT',
  COURSE = 'COURSE',
}

export enum SpeakerSectionDisplayTypes {
  BY_NAME = 'BY_NAME',
  BY_TYPE = 'BY_TYPE',
}

export enum SpeakerDisplayStyleTypes {
  BY_EVENT = 'BY_EVENT',
  TOGETHER = 'TOGETHER',
}

export interface SpeakersModuleType extends HubModule {
  '@type': HubModuleTypes.Speakers;
  link: HubModuleCTA;
  title: string;
  subtitle: string;
  displayStyle: SpeakerDisplayStyleTypes;
  speakers: SpeakerSection[];
  speakersFromDiscovery: HubSpeakersDiscoveryBlock[];
}
