import s from './CenterStageMemberIcon.module.scss';
import centerStageIcon from '../../../assets/images/center-stage-icon.svg';

export default function CenterStageMemberIcon() {
  return (
    <a
      href="https://informaconnect.com/center-stage/"
      target="_blank"
      rel="noopener noreferrer"
      className={s.wrapper}
    >
      <img src={centerStageIcon} alt="Center Stage Member" />
    </a>
  );
}
