import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import cn from 'classnames';
import {
  SiteBuilderModule,
  StyledHeading,
  StyledParagraph,
  StyledCallToAction,
} from '../sharedComponents';
import CarouselDisplayStyle from './CarouselDisplayStyle';
import { Wrapper } from './index.style';
import { SpeakerCard } from './innerComponents';

export default function SpeakersModule({ section }) {
  const {
    title,
    titleSize,
    subtitle,
    link,
    promotedSpeakers,
    showCompanyLogos,
    displaySettings,
    columnLayoutAlignment = 'CENTRE',
  } = section;

  const themeConfig = useContext(ThemeContext);
  const vStyles = themeConfig.siteBuilderModules.speakersModule.visualStyles;

  const showHeader = title || subtitle;
  const showFooter = link?.shown;

  return (
    <SiteBuilderModule section={section} moduleTheme={{ vStyles }}>
      <Wrapper>
        {showHeader && (
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-9">
                {title && (
                  <StyledHeading
                    content={title}
                    as={titleSize}
                    visualStyles={vStyles}
                  />
                )}
                {subtitle && <StyledParagraph content={subtitle} />}
              </div>
            </div>
          </div>
        )}
        {displaySettings === 'STANDARD' && (
          <div className="container">
            <div
              className={cn('row', {
                'center-sm': columnLayoutAlignment === 'CENTRE',
                'end-sm': columnLayoutAlignment === 'RIGHT',
              })}
            >
              {promotedSpeakers?.map((item, index) => (
                <div key={index} className="col-xs-12 col-sm-3">
                  <SpeakerCard
                    {...item}
                    primary={Boolean(index % 2)}
                    showCompanyLogo={showCompanyLogos}
                    style={{ padding: '20px 0' }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {displaySettings === 'CAROUSEL' && (
          <CarouselDisplayStyle section={section} />
        )}
        {showFooter && (
          <div className="text-center">
            <StyledCallToAction ctaConfig={link} visualStyles={vStyles} />
          </div>
        )}
      </Wrapper>
    </SiteBuilderModule>
  );
}
