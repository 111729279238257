import React, { useMemo } from 'react';
import { SpeakersModuleType } from '../types';
import { PropsWithTestId } from '../../../../../../../types';
import { DiscoveryBrandSpeaker } from '../../../../../../../store/features/discovery';
import cn from 'classnames';
import SpeakerCard from '../../../../productFilter/cards/SpeakerCard';
import s from './index.module.scss';

export interface SpeakersTogetherDisplayStyleProps {
  speakersFromDiscovery: SpeakersModuleType['speakersFromDiscovery'];
}

interface SpeakerTogether extends DiscoveryBrandSpeaker {
  url: string;
  domain: string;
}

function filterByUniqueId(arr: SpeakerTogether[]) {
  const uniqueIds = new Set();
  return arr.filter((obj) => {
    if (!uniqueIds.has(obj.id)) {
      uniqueIds.add(obj.id);
      return true;
    }
    return false;
  });
}

export default function SpeakersTogetherDisplayStyle(
  props: PropsWithTestId<SpeakersTogetherDisplayStyleProps>,
) {
  const { speakersFromDiscovery, testId } = props;

  const speakersTogether = useMemo(() => {
    return speakersFromDiscovery.reduce((acc, speakerBlock) => {
      const speakerType =
        speakerBlock.contentType === 'EVENT' ? 'speakers' : 'trainers';
      const speakersWithUrl = speakerBlock.speakers.map((speaker) => {
        const url = `${speakerBlock.sitePath}/${speakerType}/${speaker.path}`;
        return {
          ...speaker,
          url,
          domain: speakerBlock.domain,
        };
      });

      return [...acc, ...speakersWithUrl];
    }, [] as SpeakerTogether[]);
  }, [speakersFromDiscovery]);

  const speakersUniqueByKey = useMemo(() => {
    return filterByUniqueId(speakersTogether);
  }, [speakersTogether]);

  return (
    <div className={s.wrapper} data-testid={testId}>
      <div className="row">
        {speakersUniqueByKey.map((speaker, index) => (
          <div
            className={cn('col-xs-12 col-md-3', s.speakerOffset)}
            key={index}
          >
            <SpeakerCard
              data={speaker}
              url={speaker.url}
              domain={speaker.domain}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

SpeakersTogetherDisplayStyle.defaultProps = {
  testId: 'speakers-together',
};
