import React from 'react';
import { PropsWithTestId } from '../../../../../../../types';
import { SpeakersModuleType } from '../types';
import { ModuleHeader } from '../../../components';
import cn from 'classnames';
import SpeakerCard from '../../../../productFilter/cards/SpeakerCard';
import s from './index.module.scss';

export interface SpeakersByEventDisplayStyleProps {
  speakersFromDiscovery: SpeakersModuleType['speakersFromDiscovery'];
}

export default function SpeakersByEventDisplayStyle(
  props: PropsWithTestId<SpeakersByEventDisplayStyleProps>,
) {
  const { speakersFromDiscovery, testId } = props;

  return (
    <div data-testid={testId}>
      {speakersFromDiscovery
        .filter((site) => site.speakers.length > 0)
        .map((speakerBlock, index) => (
          <div className={s.siteOffset} key={index}>
            <div className="row">
              <div className="col-xs-12">
                <ModuleHeader title={speakerBlock.siteName} lineAfterHeading />
              </div>
              {speakerBlock.speakers.map((speaker, speakerIndex) => {
                const speakerType =
                  speakerBlock.contentType === 'EVENT'
                    ? 'speakers'
                    : 'trainers';
                const url = `${speakerBlock.sitePath}/${speakerType}/${speaker.path}`;

                return (
                  <div
                    className={cn('col-xs-12 col-md-3', s.speakerOffset)}
                    key={speakerIndex}
                  >
                    <SpeakerCard
                      data={speaker}
                      url={url}
                      domain={speakerBlock.domain}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ))}
    </div>
  );
}

SpeakersByEventDisplayStyle.defaultProps = {
  testId: 'speakers-by-event',
};
