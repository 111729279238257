import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';

import {
  StyledSVGEmbed,
  StyledCallToAction,
  StyledBackgroundImage,
  ImageTag,
} from '../../../sharedComponents';
import photoPlaceholder from '../../../../../../assets/images/default-silhouette.png';
import { siteTypesEnum } from '../../../../../../helpers';

import { pagePaths, svgEmbedColors } from './constants';
import { getSpeakerName } from './helpers';
import {
  Wrapper,
  Body,
  Name,
  Job,
  Company,
  CompanyLogo,
  CenterStageIcon,
} from './index.style';
import CenterStageMemberIcon from '../../../../../shared/CenterStageMemberIcon';

export const SpeakerCard = ({
  forename,
  middleName,
  surname,
  company,
  jobTitle,
  path,
  showCompanyLogo,
  centerStageMember,
  companyLogo: { path: companyLogo } = {},
  photo: { path: photoPath } = {},
  primary,
  style,
}) => {
  const { siteType } = useSelector(({ pageConfig }) => pageConfig);
  const themeConfig = useContext(ThemeContext);

  const vStyles = themeConfig.module.vStyles.elements.card.elements;
  const supportingSVGPath =
    themeConfig.brandConfig.brandElements?.supporting?.path;

  return (
    <Wrapper data-testid="speaker-card" style={style}>
      <StyledCallToAction
        type="link"
        to={`/${
          siteType === siteTypesEnum().COURSE
            ? pagePaths.trainers
            : pagePaths.speakers
        }/${path}/`}
        sitePath
      >
        <StyledBackgroundImage
          path={photoPath ?? photoPlaceholder}
          imgix={!!photoPath}
          imgixParams="w=550&h=550"
          vStyles={vStyles.bgImage}
          testId="speaker-photo"
        >
          {supportingSVGPath && (
            <StyledSVGEmbed href={supportingSVGPath} colors={svgEmbedColors} />
          )}
          {centerStageMember && (
            <CenterStageIcon>
              <CenterStageMemberIcon />
            </CenterStageIcon>
          )}
        </StyledBackgroundImage>
        <Body primary={primary} vStyles={vStyles?.body}>
          <div>
            <Name data-testid="speaker-name">
              {getSpeakerName(forename, middleName, surname)}
            </Name>
            {jobTitle && <Job data-testid="speaker-job">{jobTitle}</Job>}
          </div>
          {company && (
            <Company data-testid="speaker-company">{company}</Company>
          )}
          {showCompanyLogo && (
            <CompanyLogo>
              <ImageTag src={companyLogo} />
            </CompanyLogo>
          )}
        </Body>
      </StyledCallToAction>
    </Wrapper>
  );
};

SpeakerCard.propTypes = {
  forename: PropTypes.string,
  middleName: PropTypes.string,
  surname: PropTypes.string,
  company: PropTypes.string,
  jobTitle: PropTypes.string,
  path: PropTypes.string.isRequired,
  photo: PropTypes.shape({
    path: PropTypes.string,
  }),
  companyLogo: PropTypes.shape({
    path: PropTypes.string,
  }),
  showCompanyLogo: PropTypes.bool,
  centerStageMember: PropTypes.bool,
  primary: PropTypes.bool,
};
