import styled from 'styled-components';
import { breakpoint } from '../../../../informakit/styles/shared';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;

  @media screen and (min-width: ${breakpoint.tabletBreakpoint}) {
    padding: 60px 0;
  }
`;
