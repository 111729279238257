export const pagePaths = {
  trainers: 'trainers',
  speakers: 'speakers',
};

export const svgEmbedColors = {
  color1: 'rgba(255, 255, 255, 0.5)',
  color2: 'rgba(0, 0, 0, 0)',
  color3: 'rgba(255, 255, 255, 0.2)',
};
